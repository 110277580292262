<!--
  PACKAGE_NAME : src\pages\setting\system\menu\modal-menu.vue
  FILE_NAME : modal-menu
  AUTHOR : supark
  DATE : 2021-07-28
  DESCRIPTION : 하위 메뉴 등록 팝업 컴포넌트
-->
<template>
  <transition>
    <div ref="contentsWrap" class="contents-wrap" id="contentsWrap">
      <div class="contents-box">
        <div class="conts-row" v-if="formData.menuDepth > 1">
          <div class="conts-box width100per">
            <div class="conts-title">상위메뉴</div>
            <div v-if="formData.menuDepth > 1">
              {{ dept1Menu.menuNm }}
            </div>
            <div v-if="formData.menuDepth > 2">
              {{ dept2Menu.menuNm }}
            </div>
            <div v-if="formData.menuDepth > 3">
              {{ dept3Menu.menuNm }}
            </div>
          </div>
        </div>
        <div class="conts-row">
          <div class="conts-box width100per">
            <div class="conts-title">메뉴명*</div>
            <DxTextBox v-model="formData.menuNm" styling-mode="outlined" :width="350">
              <DxValidator>
                <DxRequiredRule message="메뉴명은 필수입니다." />
              </DxValidator>
            </DxTextBox>
            <div class="limitLength-box">
              <span>{{ formData.menuNm !== null ? formData.menuNm.length : 0 }}</span
              >/{{ limitLength.menuNm }}
            </div>
          </div>
        </div>

        <div class="conts-row">
          <div class="conts-box width100per">
            <div class="conts-title">메뉴유형*</div>

            <DxSelectBox
              placeholder="선택"
              :items="enums.common.menuType.values"
              display-expr="label"
              value-expr="value"
              v-model="formData.menuTypeCd"
              styling-mode="outlined"
              :width="200"
              :height="34"
              item-template="itemTemplate"
            >
              <template #itemTemplate="{ data }">
                <div :title="getMenuTypeTooltip(data.value)">
                  {{ data.label }}
                </div>
              </template>
              <DxValidator>
                <DxRequiredRule message="메뉴유형은 필수입니다." />
              </DxValidator>
            </DxSelectBox>
          </div>
        </div>
        <div class="conts-row" v-if="formData.menuDepth === 1">
          <div class="conts-box width100per">
            <div class="conts-title">메뉴아이콘</div>
            <DxSelectBox
              placeholder="선택"
              id="custom-templates"
              :data-source="theme.menuIcons"
              display-expr="label"
              value-expr="value"
              v-model="formData.menuIcon"
              field-template="field"
              item-template="item"
              :width="200"
              styling-mode="outlined"
            >
              <DxValidator>
                <DxRequiredRule message="아이콘 선택은 필수입니다." />
              </DxValidator>
              <template #field="{ data }">
                <div style="vertical-align: middle; display: flex" v-if="data">
                  <div style="padding-top: 3px; padding-left: 2px">
                    <img :src="data.src" width="23" height="23" />
                  </div>
                  <DxTextBox id="menu_config_menu_icon" :value="data && data.label" :read-only="true" />
                </div>
                <div style="vertical-align: middle; display: flex" v-else>
                  <DxTextBox id="menu_config_menu_icon" value="아이콘 선택" :read-only="true" />
                </div>
              </template>
              <template #item="{ data }">
                <div>
                  <img :src="data.src" width="23" height="23" />
                  <span style="vertical-align: middle">
                    {{ data.label }}
                  </span>
                </div>
              </template>
            </DxSelectBox>
          </div>
        </div>

        <div class="conts-row" v-if="formData.menuDepth > 2 || formData.menuTypeCd === 4">
          <div class="conts-box width100per">
            <div class="conts-title">Page Url</div>
            <DxTextBox v-model="formData.pageUrl" styling-mode="outlined" :width="350"></DxTextBox>
            <div class="limitLength-box">
              <span>{{ formData.pageUrl && formData.pageUrl !== null ? formData.pageUrl.length : 0 }}</span
              >/{{ limitLength.pageUrl }}
            </div>
          </div>
        </div>
        <div class="conts-row">
          <div class="conts-box width100per">
            <div class="conts-title">사용여부</div>
            <div class="conts-input">
              <DxSwitch
                @value-changed="viewFlValueChange($event)"
                :value="getViewFl"
                switched-off-text="OFF"
                switched-on-text="ON"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
  import { DxTextBox } from 'devextreme-vue/text-box';
  import { DxValidator, DxRequiredRule } from 'devextreme-vue/validator';
  import DxSwitch from 'devextreme-vue/switch';
  import { DxSelectBox } from 'devextreme-vue/select-box';
  import enums from '@/configs/enums';

  export default {
    components: {
      DxTextBox,
      DxValidator,
      DxRequiredRule,
      DxSwitch,
      DxSelectBox,
    },
    props: {
      contentData: Object,
      iconData: Array,
      menuList: Array,
    },
    watch: {
      formData: {
        handler(val) {
          let filterData = val;
          if (filterData) {
            this.$emit('input', filterData);
          }
        },
        deep: true,
      },
    },
    data() {
      return {
        formData: {
          menuNm: null,
          pageUrl: null,
          menuTypeCd: null,
          viewFl: 'Y',
        },
        limitLength: {
          menuNm: 50,
          pageUrl: 100,
        },
        listData: [],
        messageData: null,
      };
    },
    computed: {
      enums() {
        return enums;
      },
      theme() {
        return this.$_theme;
      },
      getViewFl() {
        return this.formData.viewFl === 'Y';
      },
      dept3Menu() {
        if (this.formData.menuDepth === 4) {
          return this.menuList.find(d => d.id === this.formData.parentId && d.menuDepth === 3);
        } else {
          return null;
        }
      },
      dept2Menu() {
        if (this.dept3Menu) {
          return this.menuList.find(d => d.id === this.dept3Menu.parentId && d.menuDepth === 2);
        } else {
          return this.menuList.find(d => d.id === this.formData.parentId && d.menuDepth === 2);
        }
      },
      dept1Menu() {
        if (this.dept2Menu) {
          return this.menuList.find(d => d.id === this.dept2Menu.parentId && d.menuDepth === 1);
        } else {
          return this.menuList.find(d => d.id === this.formData.parentId);
        }
      },
    },
    methods: {
      viewFlValueChange(e) {
        if (e.value) this.formData.viewFl = 'Y';
        else this.formData.viewFl = 'N';
      },
      createdData() {
        this.formData = { ...this.formData, ...this.contentData };
      },
      /**
       * 메뉴 유형 툴팁 반환
       *
       * @param menuTypeCd
       * @returns {*}
       */
      getMenuTypeTooltip(menuTypeCd) {
        let tooltip = '';
        switch (menuTypeCd) {
          case this.enums.common.menuType.NORMAL_MENU.value:
            tooltip = '일반 페이지의 상위 메뉴(대메뉴/중메뉴/소메뉴)';
            break;
          case this.enums.common.menuType.LINK.value:
            tooltip = '링크 이동을 위한 메뉴';
            break;
          case this.enums.common.menuType.NORMAL_PAGE.value:
            tooltip = '일반 페이지';
            break;
          case this.enums.common.menuType.REPORT.value:
            tooltip = '보고서 관련 메뉴';
            break;
          default:
            tooltip = '';
            break;
        }
        return tooltip;
      },
    },
    created() {
      this.createdData();
    },
    mounted() {},
    destroyed() {},
  };
</script>

<style scoped>
  .contents-title-box .contents-title {
    height: 40px;
    padding-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }

  .contents-box {
    width: 100%;
    padding: 20px 10px;
    background-color: #fff;
  }

  .conts-row:not(:last-child) {
    margin-bottom: 20px;
  }

  .conts-row .conts-box {
    display: inline-block;
  }

  .conts-row .conts-box > div {
    display: inline-block;
  }

  .conts-row .conts-box .conts-title {
    width: 120px;
    display: inline-block;
    vertical-align: top;
  }

  .conts-row .conts-box .conts-input {
    display: -webkit-inline-box;
  }

  .conts-row .conts-box .conts-input .limitLength-box {
    margin-left: 5px;
    display: inline-block;
    font-size: 0.9em;
  }
</style>
<style>
  #modal_menu_config_menuicon .dx-texteditor-input {
    font-size: 15px;
    padding-top: 0px;
    padding-left: 2px;
  }
</style>
